import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addErrorMessage } from "../features/errorMessageSlice";

export const useDebounce = (value, delay, regex, message) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      const isValid = regex.test(value);
      if (isValid || value === "") {
        setDebouncedValue(value);
      } else {
        setDebouncedValue("");
        dispatch(addErrorMessage(message));
      }
    }, delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay, regex, dispatch, message]);

  return debouncedValue;
};
