import React from "react";
import { Typography } from "@mui/material";
import { Translate } from "react-translated";

export const DialogBoldWithRegularText = ({ num }) => {
  return (
    <Typography variant="body5" paragraph sx={{ mb: 1 / 2 }}>
      <b>
        <Translate text={`privacy_statement_content_${num}b`} />
      </b>{" "}
      <span>
        <Translate text={`privacy_statement_content_${num}`} />
      </span>
    </Typography>
  );
};
