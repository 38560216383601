import React from "react";
import { Typography } from "@mui/material";
import { Translate } from "react-translated";

export const DialogUnorderList = ({ arrayData, sx }) => {
  return (
    <ul
      style={{
        ...sx,
        paddingLeft: "40px",
        fontSize: "14px",
        whiteSpace: "pre-wrap",
      }}
    >
      {arrayData?.map((item) => (
        <Typography
          variant="body5"
          component="li"
          sx={{ mb: "6px" }}
          key={item}
        >
          <Translate text={item} />
        </Typography>
      ))}
    </ul>
  );
};
