import React, { useEffect } from "react";
import { MainContent } from "./components/MainContent";
import { Footer } from "./components/Footer";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Provider } from "react-translated";
import translation from "./translations";
import { Box, styled } from "@mui/material";
import { useScrollToTopOnLoad } from "./hooks/useScrollToTopOnLoad";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { efTransactionId } from "./helpers/efTransactionId";
import { useDispatch } from "react-redux";
import { addField } from "./features/userDataSlice";

const AppBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  position: "relative",
  padding: "40px 20px",
  height: "100vh",

  "@media (max-width: 350px)": {
    padding: "40px 12px",
    minWidth: "290px",
  },
});

const ImageBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 0,
  overflow: "hidden",
  objectFit: "cover",
  height: "100vh",

  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },

  [theme.breakpoints.up("md")]: {
    height: "auto",
  },
}));

const App = () => {
  const language = process.env.REACT_APP_LANGUAGE;
  const dispatch = useDispatch();
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;
  useScrollToTopOnLoad();
  const helmetContext = {};

  useEffect(() => {
    const EF = window.EF;
    if (typeof window !== "undefined" && EF !== null) {
      EF.click(efTransactionId()).then((tid) => {
        tid && dispatch(addField({ ef_transaction_id: tid }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const backgroundImage = require(`./images/backgroundImage_${dynamicType}.png`);

  return (
    <HelmetProvider context={helmetContext}>
      <Provider language={language} translation={translation}>
        <ThemeProvider theme={theme}>
          <Helmet>
            <title>{process.env.REACT_APP_TITLE || "IntimFlørts"}</title>
            <meta
              name="description"
              content={
                process.env.REACT_APP_DESCRIPTION ||
                "Bli Med og Flørt - Møt Din Match I Dag"
              }
            />
          </Helmet>
          <AppBox>
            <ImageBox
              component="img"
              src={backgroundImage}
              alt="homeBgImage"
              loading="lazy"
            />
            <MainContent />
          </AppBox>
          <Footer />
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
