import React from "react";

export const DialogImagSVG = ({ src, sx }) => {
  return (
    <img
      src={src}
      style={{
        ...sx,
        display: "inline-block",
        position: "relative",
      }}
      alt={src}
    />
  );
};
