export const buttons = {
  //Common Translation Keys
  "Next_casual": { 
    en: `Next`,
    no: `Neste`,
  },
  "Next_mainstream": { 
    en: `Next`,
    no: `Neste`,
  },
  "Complete_casual": {
    en: `Complete`,
    no: `Fullfør`,
  },
  "Complete_mainstream": {
    en: `Complete`,
    no: `Fullfør`,
  },
  "Back_casual": {
    en: `Back`,
    no: `Tillbake`,
  },
  "Back_mainstream": {
    en: `Back`,
    no: `Tillbake`,
  },
  "Close_casual": {
    en: `Close`,
    no: `Lukk`,
  },
  "Close_mainstream": {
    en: `Close`,
    no: `Lukk`,
  },
};
