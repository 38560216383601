import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, styled, Box } from "@mui/material";
import { StepperComp } from "../common/StepperComp";
import { Username } from "./Username";
import { Age } from "./Age";
import { Gender } from "./Gender";
import { Email } from "./Email";
import { Location } from "./Location";
import { Password } from "./Password";
import { HaveAccount } from "../common/HaveAccount";
import { WhatLookingFor } from "./WhatLookingFor";
import { WhoLookingFor } from "./WhoLookingFor";
import { QuestionsComp } from "../common/QuestionsComp";
import { ButtonBack } from "../common/ButtonBack";
import { back } from "../../features/activeStepSlice";
import { addErrorMessage } from "../../features/errorMessageSlice";

const FormCompPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: "12px",
  boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.08)",
  padding: "24px 16px",
  background: "#fff",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("sm")]: {
    height: "auto",
    width: "500px",
    padding: "28px 40px",
  },

  [theme.breakpoints.up("md")]: {
    width: "760px",
    padding: "48px 100px",
  },

  [theme.breakpoints.up("lg")]: {
    padding: "48px 120px 32px",
  },
}));

const ActiveStepBox = styled(Box)(({ theme }) => ({
  minHeight: "200px",

  [theme.breakpoints.up("md")]: {
    height: "auto",
  },
}));

export const FormComp = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const isLoading = useSelector((state) => state.isLoading.value);
  const steps = 8;
  const dispatch = useDispatch();

  const stepsCompArr = [
    <WhatLookingFor />,
    <Gender />,
    <WhoLookingFor />,
    <Age />,
    <Location />,
    <Username />,
    <Password />,
    <Email />,
  ];

  const handleBack = () => {
    dispatch(back());
    dispatch(addErrorMessage(""));
  };

  return (
    <Box sx={{ minWidth: "fit-content" }}>
      <FormCompPaper>
        {activeStep < steps && <StepperComp active={activeStep} />}

        <Box sx={{ flex: "1 1 auto" }}>
          <QuestionsComp />
          <ActiveStepBox>
            {!isLoading && (
              <>
                {stepsCompArr[activeStep]}

                {activeStep > 0 && <ButtonBack onClick={handleBack} />}
              </>
            )}
          </ActiveStepBox>
        </Box>
        <HaveAccount />
      </FormCompPaper>
    </Box>
  );
};
