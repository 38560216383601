export const mainContent = {
  //Looking For component content
  "What are you looking for?_casual": {
    en: `What are you looking for?`,
    no: `Hei!😘Før du kan se meldingen jeg sendte deg må du registrere deg 🤭 Hva ser du etter? 🤔`,
  },
  "What are you looking for?_mainstream": {
    en: `What are you looking for?`,
    no: `Hei, jeg er coachen til IntimFlørts. Hva ser du etter? 😇`,
  },
  "Casual Dating_casual": {
    en: `One Night Stand`,
    no: `Pulevenn`,
  },
  "Casual Dating_mainstream": {
    en: `One Night Stand`,
    no: `One Night Stand`,
  },
  "Couple of Dates_casual": {
    en: `Friendships`,
    no: `Vennskap`,
  },
  "Couple of Dates_mainstream": {
    en: `Friendships`,
    no: `Vennskap`,
  },
  "Serious Relationship_casual": {
    en: `Serious Relationship`,
    no: `Seriøst Forhold`,
  },
  "Serious Relationship_mainstream": {
    en: `Serious Relationship`,
    no: `Seriøst Forhold`,
  },
  //Gender component content
  "What is your gender?_casual": {
    en: `What is your gender?`,
    no: `Bra svar!😈 Er du mann eller kvinne? 🧑‍🤝‍🧑`,
  },
  "What is your gender?_mainstream": {
    en: `What is your gender?`,
    no: `Er du mann eller kvinne? 🧑‍🤝‍🧑`,
  },
  "Male_casual": {
    en: `I am a man`,
    no: `Jeg er en mann`,
  },
  "Male_mainstream": {
    en: `I am a man`,
    no: `Jeg er en mann`,
  },
  "Female_casual": {
    en: `I am a woman`,
    no: `Jeg er en kvinne`,
  },
  "Female_mainstream": {
    en: `I am a woman`,
    no: `Jeg er en kvinne`,
  },
  //Looking for component content
  "Who are you looking for?_casual": {
    en: `Who are you looking for?`,
    no: `Interessant!😜 Hva leter du etter? 😉`,
  },
  "Who are you looking for?_mainstream": {
    en: `Who are you looking for?`,
    no: `Spennende! Hva er du ute etter? 😉`,
  },
  "lookingFemale_casual": {
    en: `Seeking a woman`,
    no: `Kvinner`,
  },
  "lookingFemale_mainstream": {
    en: `Seeking a woman`,
    no: `Kvinner`,
  },
  "lookingMale_casual": {
    en: `Seeking a man`,
    no: `Menn`,
  },
  "lookingMale_mainstream": {
    en: `Seeking a man`,
    no: `Menn`,
  },
  //Age component content
  "What is your age?_casual": {
    en: `When were you born?`,
    no: `Siden profilen min er litt på kanten 💦🍆, må jeg å bekrefte at du er voksen. Kan du fortelle meg når du er født? 😉`,
  },
  "What is your age?_mainstream": {
    en: `When were you born?`,
    no: `Kan du gi meg fødselsdatoen din slik at jeg kan finne de beste matchene for deg? ✨`,
  },
  "18 years old_casual": {
    en: `You must be at least 18 years old to use the website`,
    no: `Du må være minst 18 år for å bruke nettstedet`,
  },
  "18 years old_mainstream": {
    en: `You must be at least 18 years old to use the website`,
    no: `Du må være minst 18 år for å bruke nettstedet`,
  },
  "day_casual": {
    en: `Day`,
    no: `Dag`,
  },
  "day_mainstream": {
    en: `Day`,
    no: `Dag`,
  },
  "month_casual": {
    en: `Month`,
    no: `Måned`,
  },
  "month_mainstream": {
    en: `Month`,
    no: `Måned`,
  },
  "year_casual": {
    en: `Year`,
    no: `År`,
  },
  "year_mainstream": {
    en: `Year`,
    no: `År`,
  },
  //Location component content
  "What is your location?_casual": {
    en: `What is your location?`,
    no: `Supert, takk! 💋 Hvor er det du bor, i hvilken by? 📍`,
  },
  "What is your location?_mainstream": {
    en: `What is your location?`,
    no: `Tusen takk! Hvilken by bor du i? 📍`,
  },
  "search location_casual": {
    en: `Your location will never be shared with third parties, your data is secure`,
    no: `Dine data vil forbli trygge, og din posisjon deles aldri med eksterne parter`,
  },
  "search location_mainstream": {
    en: `Your location will never be shared with third parties, your data is secure`,
    no: `Dine data vil forbli trygge, og din posisjon deles aldri med eksterne parter`,
  },
  "searchPlaceholder_casual": {
    en: `Enter your city`,
    no: `Skriv inn byen din`,
  },
  "searchPlaceholder_mainstream": {
    en: `Enter your city`,
    no: `Skriv inn byen din`,
  },
  "locationErrMessFE_casual": {
    en: `Location not found`,
    no: `Plassering ikke funnet`,
  },
  "locationErrMessFE_mainstream": {
    en: `Location not found`,
    no: `Plassering ikke funnet`,
  },
  //Email component content
  "Add your email address_casual": {
    en: `Add your email address`,
    no: `Wow, du er jo rett i nærheten😱❤️! La meg sette opp en konto for deg, så vi kan fortsette å snakke der. Kan jeg få e-postadressen din?`,
  },
  "Add your email address_mainstream": {
    en: `Add your email address`,
    no: `Jeg har funnet medlemmer i ditt område ❤️! Jeg oppretter en konto for deg, slik at du kan se profilene deres gratis. Kan du gi meg e-postadressen din?`,
  },
  "email value_casual": {
    en: `Email`,
    no: `E-post`,
  },
  "email value_mainstream": {
    en: `Email`,
    no: `E-post`,
  },
  "read and accept_casual": {
    en: `I have read and accepted the`,
    no: `Jeg har lest og godtatt`,
  },
  "read and accept_mainstream": {
    en: `I have read and accepted the`,
    no: `Jeg har lest og godtatt`,
  },
  "and our_casual": {
    en: `and our`,
    no: `og vår`,
  },
  "and our_mainstream": {
    en: `and our`,
    no: `og vår`,
  },
  "Privacy Statement_casual": {
    en: `Privacy Statement`,
    no: `Personvernerklæring`,
  },
  "Privacy Statement_mainstream": {
    en: `Privacy Statement`,
    no: `Personvernerklæring`,
  },
  "emailErrMessFE_casual": {
    en: `Please enter a valid email address to proceed`,
    no: `Vennligst oppgi en gyldig e-postadresse for å fortsette`,
  },
  "emailErrMessFE_mainstream": {
    en: `Please enter a valid email address to proceed`,
    no: `Vennligst oppgi en gyldig e-postadresse for å fortsette`,
  },
  //UserName component content
  "Choose a username_casual": {
    en: `Choose a username`,
    no: `Snart ferdig!🙈 Hvilket brukernavn foretrekker du? 😘`,
  },
  "Choose a username_mainstream": {
    en: `Choose a username`,
    no: `Nesten ferdig! Kan du velge et brukernavn? 🤟`,
  },
  "username value_casual": {
    en: `Username`,
    no: `Brukernavn`,
  },
  "username value_mainstream": {
    en: `Username`,
    no: `Brukernavn`,
  },
  "username text_casual": {
    en: `Username must be 3-12 characters should exclude any personal details.`,
    no: `Brukernavnet må være 3-12 tegn og bør utelukke personlige detaljer.`,
  },
  "username text_mainstream": {
    en: `Username must be 3-12 characters should exclude any personal details.`,
    no: `Brukernavnet må være 3-12 tegn og bør utelukke personlige detaljer.`,
  },
  "usernameErrMessFE_casual": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },
  "usernameErrMessFE_mainstream": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },
  //Password component content
  "Set your password_casual": {
    en: `Set your password`,
    no: `Det er bare å opprette et passord nå, så kan vi bli bedre kjent❤️ Pass på at det er noe du enkelt husker 😊`,
  },
  "Set your password_mainstream": {
    en: `Set your password`,
    no: `Siste steg, lag et passord du klarer å huske 😊`,
  },
  "password text_casual": {
    en: `The password must be more than 6 characters long and include at least one number`,
    no: `Passordet må være lengre enn 6 tegn og inkludere minst ett tall`,
  },
  "password text_mainstream": {
    en: `The password must be more than 6 characters long and include at least one number`,
    no: `Passordet må være lengre enn 6 tegn og inkludere minst ett tall`,
  },
  "password value_casual": {
    en: `Password`,
    no: `Passord`,
  },
  "password value_mainstream": {
    en: `Password`,
    no: `Passord`,
  },
  "passErrMessFE_casual": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit`,
    no: `Passordet ditt oppfyller ikke våre retningslinjer. Passordet må være 6-20 tegn og inneholde minst ett tall.`,
  },
  "passErrMessFE_mainstream": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit`,
    no: `Passordet ditt oppfyller ikke våre retningslinjer. Passordet må være 6-20 tegn og inneholde minst ett tall.`,
  },
  //Have an account component content
  "have account_casual": {
    en: `Already have an account?`,
    no: `Har du allerede en konto?`,
  },
  "have account_mainstream": {
    en: `Already have an account?`,
    no: `Har du allerede en konto?`,
  },
  "log In_casual": {
    en: `Log In`,
    no: `Logg Inn`,
  },
  "log In_mainstream": {
    en: `Log In`,
    no: `Logg Inn`,
  },
  //Footer component content
  "Terms_casual": {
    en: `Terms`,
    no: `Vilkår`,
  },
  "Terms_mainstream": {
    en: `Terms`,
    no: `Vilkår`,
  },
  "Terms of Service_casual": {
    en: `Terms of Service`,
    no: `Tjenestevilkår`,
  },
  "Terms of Service_mainstream": {
    en: `Terms of Service`,
    no: `Tjenestevilkår`,
  },
  "Policy_casual": {
    en: `Policy`,
    no: `Personvernerklæring`,
  },
  "Policy_mainstream": {
    en: `Policy`,
    no: `Personvernerklæring`,
  },
  "Cookie Statement_casual": {
    en: `Cookie Statement`,
    no: `Informasjonskapselerklæring`,
  },
  "Cookie Statement_mainstream": {
    en: `Cookie Statement`,
    no: `Informasjonskapselerklæring`,
  },
  "Help Center_casual": {
    en: `Help Center`,
    no: `Hjelpesenter`,
  },
  "Help Center_mainstream": {
    en: `Help Center`,
    no: `Hjelpesenter`,
  },
  "rights reserved_casual": {
    en: `All Rights Reserved.`,
    no: `Alle rettigheter reservert.`,
  },
  "rights reserved_mainstream": {
    en: `All Rights Reserved.`,
    no: `Alle rettigheter reservert.`,
  },
  //error message translation content
  "Field already exists._casual": {
    en: `An account containing identical details already exists in our system`,
    no: `En konto med identiske opplysninger finnes allerede i vårt system`,
  },
  "Field already exists._mainstream": {
    en: `An account containing identical details already exists in our system`,
    no: `En konto med identiske opplysninger finnes allerede i vårt system`,
  },
    "Provide valid email._casual": {
    en: `Please enter a valid email address to proceed`,
    no: `Vennligst oppgi en gyldig e-postadresse for å fortsette`,
  },
  "Provide valid email._mainstream": {
    en: `Please enter a valid email address to proceed`,
    no: `Vennligst oppgi en gyldig e-postadresse for å fortsette`,
  },
  "Google recaptcha error._casual": {
    en: `There has been an error with the Google reCAPTCHA verification process`,
    no: `Det har oppstått en feil med Google reCAPTCHA-verifiseringsprosessen`,
  },
  "Google recaptcha error._mainstream": {
    en: `There has been an error with the Google reCAPTCHA verification process`,
    no: `Det har oppstått en feil med Google reCAPTCHA-verifiseringsprosessen`,
  },
  "Provide google recaptcha token._casual": {
    en: `Please provide the token generated by the Google reCAPTCHA for verification`,
    no: `Vennligst oppgi tokenet generert av Google reCAPTCHA for verifikasjon`,
  },
  "Provide google recaptcha token._mainstream": {
    en: `Please provide the token generated by the Google reCAPTCHA for verification`,
    no: `Vennligst oppgi tokenet generert av Google reCAPTCHA for verifikasjon`,
  },
  "Google recaptcha user suspicious behaviour._casual": {
    en: `Google reCAPTCHA has detected suspicious activity associated with your behavior`,
    no: `Google reCAPTCHA har oppdaget mistenkelig aktivitet knyttet til din oppførsel`,
  },
  "Google recaptcha user suspicious behaviour._mainstream": {
    en: `Google reCAPTCHA has detected suspicious activity associated with your behavior`,
    no: `Google reCAPTCHA har oppdaget mistenkelig aktivitet knyttet til din oppførsel`,
  },
  "Wrong user id provided._casual": {
    en: `The user ID you have provided is incorrect or does not exist`,
    no: `Bruker-ID-en du har oppgitt er feil eller eksisterer ikke`,
  },
  "Wrong user id provided._mainstream": {
    en: `The user ID you have provided is incorrect or does not exist`,
    no: `Bruker-ID-en du har oppgitt er feil eller eksisterer ikke`,
  },
  "User age must be above 18 years._casual": {
    en: `Users must be at least 18 years old to create an account`,
    no: `Brukere må være minst 18 år gamle for å opprette en konto`,
  },
  "User age must be above 18 years._mainstream": {
    en: `Users must be at least 18 years old to create an account`,
    no: `Brukere må være minst 18 år gamle for å opprette en konto`,
  },
  //Duplicate from backend error response
  "Username requirements don't match._casual": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },
  "Username requirements don't match._mainstream": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },
  "Password requirements don't match._casual": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit.`,
    no: `Ditt passord overholder ikke våre krav. Det skal være på 6-20 tegn og må inkludere minst ett tall.`,
  },
  "Password requirements don't match._mainstream": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit.`,
    no: `Ditt passord overholder ikke våre krav. Det skal være på 6-20 tegn og må inkludere minst ett tall.`,
  },
};