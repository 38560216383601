import React, { useState } from "react";
import { Box } from "@mui/material";
import { ButtonComp } from "../common/ButtonComp";
import { ButtonNext } from "../common/ButtonNext";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { next } from "../../features/activeStepSlice";

export const WhatLookingFor = () => {
  const userData = useSelector((state) => state.userData?.value);
  const [whatLooking, setWhatLooking] = useState(userData.whatLooking || "");
  const dispatch = useDispatch();

  const handleChange = (selectedValue) => {
    setWhatLooking(selectedValue);
    dispatch(addField({ whatLooking: selectedValue }));
  };

  const handleNext = () => {
    if (whatLooking) {
      dispatch(next());
    }
  };

  return (
    <Box>
      <ButtonComp
        sx={{
          border: `1px solid ${
            whatLooking === "Casual Dating" ? "#F76448" : "#B2B3B5"
          }`,
          color:
            whatLooking === "Casual Dating"
              ? "secondary.main"
              : "primary.light",
          marginBottom: "16px",
        }}
        onClick={() => handleChange("Casual Dating")}
        text="Casual Dating"
      />

      <ButtonComp
        sx={{
          border: `1px solid ${
            whatLooking === "Couple of Dates" ? "#F76448" : "#B2B3B5"
          }`,
          color:
            whatLooking === "Couple of Dates"
              ? "secondary.main"
              : "primary.light",
          marginBottom: "16px",
        }}
        onClick={() => handleChange("Couple of Dates")}
        text="Couple of Dates"
      />

      <ButtonComp
        sx={{
          border: `1px solid ${
            whatLooking === "Serious Relationship" ? "#F76448" : "#B2B3B5"
          }`,
          color:
            whatLooking === "Serious Relationship"
              ? "secondary.main"
              : "primary.light",
        }}
        onClick={() => handleChange("Serious Relationship")}
        text="Serious Relationship"
      />

      <ButtonNext onClick={handleNext} text="Next" disabled={!whatLooking} />
    </Box>
  );
};
