import { Link, Typography } from "@mui/material";
import React from "react";
import { Translate } from "react-translated";

export const LinkComp = ({ link, onClick }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Link
      onClick={onClick}
      underline="none"
      sx={{
        ...link.sx,
        fontSize: {
          xs: "12px",
          sm: "18px",
        },
        color: "primary.main",
        fontWeight: "400",
        lineHeight: "26px",
        cursor: "pointer",
        userSelect: "none",
        display: "flex",
      }}
    >
      <Translate text={`${link.text}_${dynamicType}`} />
      {link.id !== 2 && <Typography sx={{ mx: 1 }}> • </Typography>}
    </Link>
  );
};
