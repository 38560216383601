import React from "react";
import { TextField, styled, Box } from "@mui/material";
import { Translator } from "react-translated";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const InputBoxWrapper = styled(Box)(() => ({
  border: "1px solid #F76448",
  width: "100%",
  borderRadius: "16px",
  height: "48px",
  padding: "0 12px",
  display: "flex",
  alignItems: "center",
}));

const InputBox = styled(TextField)(() => ({
  fontSize: "18px",
  width: "94%",
}));

export const Input = ({
  value,
  handleChange,
  placeholder,
  typeInput,
  handleValidation,
  isValid = false,
}) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <InputBoxWrapper>
      <Translator>
        {({ translate }) => (
          <InputBox
            placeholder={translate({
              text: `${placeholder}_${dynamicType}`,
            })}
            className="inputBox"
            value={value}
            onChange={handleChange}
            onBlur={handleValidation}
            type={typeInput}
            autoFocus
          />
        )}
      </Translator>
      {!isValid && <CheckCircleIcon style={{ color: "#84d31c" }} />}
    </InputBoxWrapper>
  );
};
