import React from "react";
import { Typography } from "@mui/material";
import { Translate } from "react-translated";

export const DialogParagraph = ({ text, sx }) => {
  return (
    <Typography
      variant="body5"
      paragraph
      sx={{
        ...sx,
        my: 1 / 2,
        mb: 1,
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
      }}
    >
      <Translate text={text} />
    </Typography>
  );
};
