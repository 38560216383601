import { Typography } from "@mui/material";
import React from "react";
import { Translate } from "react-translated";

export const ButtonBack = ({ disabled = false, onClick = null }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Typography
      onClick={onClick}
      disabled={disabled}
      sx={{
        fontSize: "18px",
        borderRadius: "16px",
        textTransform: "none",
        textAlign: "center",
        width: "fit-content",
        cursor: "pointer",
        margin: "0 auto",
        px: 2,
      }}
      variant="body1"
    >
      <Translate text={`Back_${dynamicType}`} />
    </Typography>
  );
};
