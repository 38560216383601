import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { Translate } from "react-translated";

const ErrorMessageBox = styled(Box)(({ theme }) => ({
  background: "#F76448",
  margin: "0 14px",
  borderRadius: "0 0 14px 14px",
  padding: "5px 16px",

  [theme.breakpoints.up("sm")]: {
    margin: "0 20px",
  },
}));

export const ErrorMessage = ({ errorMessage }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <ErrorMessageBox>
      <Typography sx={{ fontSize: "14px", color: "#fff" }}>
        <Translate text={`${errorMessage}_${dynamicType}`} />
      </Typography>
    </ErrorMessageBox>
  );
};
