import React, { useEffect } from "react";
import { Box, Typography, styled, keyframes } from "@mui/material";
import { Translate } from "react-translated";
import { questionData } from "../../dataUiPart.js/questionData";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../features/isLoadingSlice";

const QuestionBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginBottom: "28px",

  [theme.breakpoints.up("sm")]: {
    marginBottom: "32px",
  },
}));

const ImageOnlineStatus = styled(Box)(() => ({
  position: "relative",
}));

const OnlineStatusBox = styled(Box)(() => ({
  width: "12px",
  height: "12px",
  background: "#74df69",
  borderRadius: "50%",
  position: "absolute",
  top: "30px",
  right: "0px",
  boxShadow: "0 0 5px #fff",
  animation: `${pulse} 1s infinite`,
}));

const pulse = keyframes`
  0% {transform: scale(1)}
  50% {transform: scale(1.1); opacity: 0.8}
  100% {transform: scale(1)}
`;

const QuestionTextBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: "#E5E8EB",
  padding: "8px 16px",
  marginLeft: "16px",
  borderRadius: "0px 12px 12px 12px",
  minHeight: "48px",
  minWidth: "180px",

  [theme.breakpoints.up("sm")]: {
    padding: "11px 32px",
    minWidth: "250px",
  },
}));

const dotAnimation = keyframes`
  from {background-color: #1e1e1ecf}
  to {background-color: #c1c1c1}
  `;

const DotBox = styled(Box)(({ time }) => ({
  width: "5px",
  height: "5px",
  borderRadius: "50%",
  animation: `${dotAnimation} ${time}s linear infinite alternate`,
  margin: "0 2px",
  background: "#333",
}));

export const QuestionsComp = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const isLoading = useSelector((state) => state.isLoading.value);
  const dispatch = useDispatch();
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;
  const chatbot = require(`./../../images/chatbot_${dynamicType}.png`);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setIsLoading(false));
    }, 1000);
    return () => {
      clearTimeout(timeout);
      dispatch(setIsLoading(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  return (
    <QuestionBox>
      <ImageOnlineStatus>
        <img src={chatbot} alt="chatbot" width={48} height={48} />
        <OnlineStatusBox />
      </ImageOnlineStatus>

      <QuestionTextBox>
        {isLoading ? (
          <Box sx={{ display: "flex" }}>
            <DotBox time={1} />
            <DotBox time={1.1} />
            <DotBox time={1.2} />
          </Box>
        ) : (
          <Typography
            sx={{
              fontSize: {
                xs: "16px",
                sm: "18px",
              },
              color: "primary.main",
              fontWeight: "400",
              lineHeight: "26px",
            }}
          >
            <Translate
              text={`${questionData[activeStep].text}_${dynamicType}`}
            />
          </Typography>
        )}
      </QuestionTextBox>
    </QuestionBox>
  );
};
