import { Divider, Link, Typography } from "@mui/material";
import React from "react";
import { Translate } from "react-translated";
import { Box, styled } from "@mui/material";

const HaveAccountBox = styled(Box)(({ theme }) => ({
  maxWidth: "656px",
  margin: "20px auto 0",
  flex: "0 0 auto",
  width: "100%",

  [theme.breakpoints.up("sm")]: {
    marginTop: "24px",
  },

  [theme.breakpoints.up("xl")]: {
    marginTop: "40px",
  },
}));

export const HaveAccount = () => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <HaveAccountBox>
      <Divider
        sx={{
          mb: {
            xs: 2,
            sm: 3,
          },
          width: "100%",
        }}
      />
      <Typography
        sx={{
          fontSize: {
            xs: "16px",
            sm: "18px",
          },
          color: "primary.main",
          fontWeight: "400",
          lineHeight: "26px",
        }}
        textAlign="center"
        display="block"
      >
        <Translate text={`have account_${dynamicType}`} />
        <Link
          color="secondary.main"
          href="https://intimflorts.com?open=login"
          sx={{ textDecoration: "none" }}
        >
          {" "}
          <Translate text={`log In_${dynamicType}`} />
        </Link>
      </Typography>
    </HaveAccountBox>
  );
};
