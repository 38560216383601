import React from "react";
import { CookieStatementDialog } from "../CookieStatementDialog";
import { GeneralTermsOfServiceDialog } from "../GeneralTermsOfServiceDialog";
import { PrivacyStatementDialog } from "../PrivacyStatementDialog";

export const DialogContentComp = ({ type }) => {
  return (
    <>
      {type === "cookie" && <CookieStatementDialog />}
      {type === "terms" && <GeneralTermsOfServiceDialog />}
      {type === "privacy" && <PrivacyStatementDialog />}
    </>
  );
};
