import { Button } from "@mui/material";
import React from "react";
import { Translate } from "react-translated";

export const ButtonComp = ({ text, disabled = false, sx, onClick = null }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        ...sx,
        height: "48px",
        width: "100%",
        fontSize: "18px",
        borderRadius: "16px",
        textTransform: "none",
      }}
    >
      <Translate text={`${text}_${dynamicType}`} />
    </Button>
  );
};
