import React from "react";
import { Typography } from "@mui/material";
import { Translate } from "react-translated";

export const DialogBoldText = ({ text, sx }) => {
  return (
    <Typography variant="body6" sx={{ ...sx }}>
      <Translate text={text} />
    </Typography>
  );
};
