import React from "react";
import { Typography } from "@mui/material";
import { Translate } from "react-translated";

export const DialogSubtitle = ({ text }) => {
  return (
    <Typography variant="h6" sx={{ mt: 3, fontSize: "18px", fontWeight:"600" }}>
      <Translate text={text} />
    </Typography>
  );
};
