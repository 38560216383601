import React from "react";
import { styled, Box } from "@mui/material";
import { FormComp } from "./form/FormComp";

const MainContentBox = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  zIndex: 10,
  minWidth: "270px",

  [theme.breakpoints.up("sm")]: {
    height: "auto",
  },
}));

export const MainContent = () => {
  return (
    <MainContentBox component="main">
      <FormComp />
    </MainContentBox>
  );
};
