export const linksData = [
  {
    id: 0,
    text: "Cookie Statement",
    type: "cookie",
  },
  {
    id: 1,
    text: "Terms of Service",
    type: "terms",
  },
  {
    id: 2,
    text: "Privacy Statement",
    type: "privacy",
  },
];
