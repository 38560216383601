import React from "react";
import { Box, styled } from "@mui/material";
import { ButtonComp } from "../common/ButtonComp";
import { useSelector } from "react-redux";

const GenderSelectedBox = styled(Box)(({ imgsrc, theme }) => ({
  backgroundImage: `url(${imgsrc})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "100%",
  minHeight: "227px",
  padding: " 166px 16px 16px",
  maxWidth: "100%",
  borderRadius: "12px",
  marginBottom: "16px",

  "&:hover": { cursor: "pointer" },

  [theme.breakpoints.up("md")]: {
    width: "250px",
  },
}));

export const ImageWithText = ({ imgsrc, text, setMethod, value }) => {
  const userData = useSelector((state) => state.userData?.value);
  const activeStep = useSelector((state) => state.activeStep.value);

  const selectedValue =
    (activeStep === 1 && userData.gender === text) ||
    (activeStep === 2 && userData.looking_for === value);

  return (
    <GenderSelectedBox imgsrc={imgsrc} onClick={() => setMethod(value)}>
      <ButtonComp
        sx={{
          color: selectedValue ? "#FFFFFFC7" : "#F76448",
          backgroundColor: selectedValue ? "#F76448" : "#FFFFFFC7",
          "&:hover": {
            backgroundColor: "#fffffff2",
            color: "#F76448",
          },
        }}
        text={text}
      />
    </GenderSelectedBox>
  );
};
