import { buttons } from "./lp1m-buttons";
import { mainContent } from "./lp1m-main-content";
import { dialog } from "./lp1m-dialog";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...buttons,
  ...mainContent,
  ...dialog,
};
