export const questionData = [
  {
    id: 0,
    text: "What are you looking for?",
  },
  {
    id: 1,
    text: "What is your gender?",
  },
  {
    id: 2,
    text: "Who are you looking for?",
  },
  {
    id: 3,
    text: "What is your age?",
  },
  {
    id: 4,
    text: "What is your location?",
  },
  {
    id: 5,
    text: "Choose a username",
  },
  {
    id: 6,
    text: "Set your password",
  },
  {
    id: 7,
    text: "Add your email address",
  },
];
