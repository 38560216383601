import React, { useState } from "react";
import { Box, styled, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { next } from "../../features/activeStepSlice";
import { addField } from "../../features/userDataSlice";
import { ImageWithText } from "../common/ImageWithText";
import { ButtonNext } from "../common/ButtonNext";
import { ButtonComp } from "../common/ButtonComp";

const ImageWithTextBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  marginBottom: "8px",

  [theme.breakpoints.up("md")]: {
    justifyContent: "space-between",
  },
}));

export const Gender = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData?.value);
  const [gender, setGender] = useState(userData.gender || "");
  const isMobile = useMediaQuery("(max-width:960px)");
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;
  const genderMan = require(`./../../images/gender_man_${dynamicType}.png`);
  const genderWoman = require(`./../../images/gender_woman_${dynamicType}.png`);

  const handleChange = (selectedGender) => {
    setGender(selectedGender);
    dispatch(addField({ gender: selectedGender }));
  };

  const handleNext = () => {
    if (gender) {
      dispatch(next());
    }
  };

  return (
    <ImageWithTextBox>
      {!isMobile ? (
        <>
          <ImageWithText
            imgsrc={genderMan}
            text="Male"
            setMethod={() => handleChange("Male")}
            value="Male"
          />
          <ImageWithText
            imgsrc={genderWoman}
            text="Female"
            setMethod={() => handleChange("Female")}
            value="Female"
          />
        </>
      ) : (
        <>
          <ButtonComp
            sx={{
              border: `1px solid ${gender === "Male" ? "#F76448" : "#B2B3B5"}`,
              color: gender === "Male" ? "secondary.main" : "primary.light",
              marginBottom: "16px",
            }}
            onClick={() => handleChange("Male")}
            text="Male"
          />
          <ButtonComp
            sx={{
              border: `1px solid ${
                gender === "Female" ? "#F76448" : "#B2B3B5"
              }`,
              color: gender === "Female" ? "secondary.main" : "primary.light",
              marginBottom: "16px",
            }}
            onClick={() => handleChange("Female")}
            text="Female"
          />
        </>
      )}
      <ButtonNext
        onClick={handleNext}
        text="Next"
        style={{ cursor: gender ? "pointer" : "no-drop" }}
        disabled={!gender}
      />
    </ImageWithTextBox>
  );
};
