import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Input } from "../common/Input";
import { ErrorMessage } from "../common/ErrorMessage";
import { ButtonNext } from "../common/ButtonNext";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { next } from "../../features/activeStepSlice";
import { useDebounce } from "../../hooks/useDebounce";
import { Translate } from "react-translated";

export const Username = () => {
  const userData = useSelector((state) => state.userData?.value);
  const [username, setUsername] = useState(userData.username || "");
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const regex = /^[_&?!#0-9a-zA-Z\u00c0-\u017e]{3,12}$/;
  const message = "usernameErrMessFE";
  const useDebounceValue = useDebounce(username, 500, regex, message);
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length < 13) {
      setUsername(value);
    }
    dispatch(addErrorMessage(""));
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await apiUrl.post(
        `/registration/start`,
        {
          username: useDebounceValue,
          recaptcha_token: null,
        },
        {
          params: {
            site_key: "no01",
          },
        }
      );
      if (response?.data.Status === "ok") {
        sessionStorage.setItem("user_id", response?.data.Data);
        dispatch(next());
        dispatch(addErrorMessage(""));
      }
    } catch (error) {
      if (error?.response?.data.Status === "fail") {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
        localStorage.removeItem("uid");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleNext = () => {
    if (useDebounceValue) {
      dispatch(addField({ username: useDebounceValue }));
      fetchData();
    }
  };

  return (
    <Box className="userBox">
      <Typography
        variant="body3"
        marginBottom={2}
        display="block"
        sx={{ lineHeight: "18px" }}
      >
        <Translate text={`username text_${dynamicType}`} />
      </Typography>
      <Input
        value={username}
        handleChange={handleChange}
        placeholder="username value"
        typeInput="text"
        isValid={!useDebounceValue || isLoading || !!errorMessage}
      />

      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

      <ButtonNext
        onClick={handleNext}
        text="Next"
        style={{ cursor: !isLoading ? "pointer" : "no-drop" }}
        disabled={!useDebounceValue || isLoading || !!errorMessage}
      />
    </Box>
  );
};
