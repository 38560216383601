import { FormControl, InputLabel, Select, styled } from "@mui/material";
import React from "react";
import { Translate } from "react-translated";

const FormControlBox = styled(FormControl)(({ theme }) => ({
  border: "1px solid #F76448",
  color: "primary.light",
  borderRadius: "12px",
  outline: "none",
  height: "48px",
  width: "30%",

  [theme.breakpoints.up("sm")]: {
    borderRadius: "16px",
  },
}));

export const FormControlComp = ({
  value,
  handleChange,
  label,
  name,
  getOption,
  labelId,
  id,
  sx,
}) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <FormControlBox fullWidth className="formControlBox" sx={sx}>
      <InputLabel id={id} sx={{ p: "0 5px" }}>
        <Translate text={`${label}_${dynamicType}`} />
      </InputLabel>
      <Select
        sx={{ padding: "0 12px" }}
        name={name}
        value={value}
        label={<Translate text={`${label}_${dynamicType}`} />}
        onChange={handleChange}
        labelId={labelId}
        id={id}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 240,
            },
          },
        }}
      >
        {getOption}
      </Select>
    </FormControlBox>
  );
};
