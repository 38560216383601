export const efTransactionId = () => {
  const EF = window.EF;
  const organic_offer_id = process.env.REACT_APP_ORGANIC_OFFER_ID;
  const default_affiliate_id = process.env.REACT_APP_DEFAULT_AFFILIATE_ID;
  const default_uid = process.env.REACT_APP_DEFAULT_UID;

  const url_transaction_id = EF.urlParameter("tid"); // transaction id - for redirect links
  const url_offer_id = EF.urlParameter("oid"); // offer id from url parameter
  const url_affiliate_id = EF.urlParameter("affid"); // affiliate id from url parameter
  const url_uid = EF.urlParameter("uid"); // landing page id from url parameter

  // set offer id for organic if parameter missing in url
  const offer_id = url_offer_id ? url_offer_id : organic_offer_id;
  // set default affiliate id if parameter missing in url
  const affiliate_id = url_affiliate_id
    ? url_affiliate_id
    : default_affiliate_id;
  // set base offer url id if parameter missing in url
  const uid = url_uid ? url_uid : default_uid;

  // Construct the click object
  const clickParams = {
    transaction_id: url_transaction_id, // For redirect links
    offer_id: offer_id, // Required: The offer id
    affiliate_id: affiliate_id, // Required: The affiliate id
    uid: uid, // Landing page
    source_id: EF.urlParameter("source_id"), // Optional: AD channel can be given as a URL parameter
    sub1: EF.urlParameter("sub1"), // Optional: Campaign id can be given as a parameter
    sub2: EF.urlParameter("sub2"), // Optional: Campaign name can be given as a parameter
    sub3: EF.urlParameter("sub3"), // Optional: Campaign name can be given as a parameter
    sub4: EF.urlParameter("device"), // Optional: Device can be given as a parameter
    sub5: EF.urlParameter("ref"), // The "ref" query string parameter is missing from the URL. Nothing would be passed as sub5
  };

  return clickParams;
};
