import React, { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";

export const RecaptchaToken = () => {
  const userData = useSelector((state) => state.userData?.value);
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const language = process.env.REACT_APP_LANGUAGE;
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    refreshReCaptchaToken();
    const intervalId = setInterval(() => {
      refreshReCaptchaToken();
    }, 60000);
    return () => clearInterval(intervalId);
  }, [userData.recaptcha_token]);

  const handleChangeRecaptchaToken = (token) => {
    dispatch(addField({ recaptcha_token: token }));
  };

  const refreshReCaptchaToken = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={siteKey}
      size="invisible"
      className="recaptchaBox"
      onChange={handleChangeRecaptchaToken}
      hl={language}
    />
  );
};
