import React from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Translate } from "react-translated";
import styled from "@emotion/styled";
import { ButtonComp } from "./ButtonComp";
import { DialogContentComp } from "../dialog/dialog_components/DialogContentComp";
import useMediaQuery from "@mui/material/useMediaQuery";

const DialogTitleComp = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontWeight: "700",
});

export const ModalComp = ({ open, handleClose, type }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const titleText = {
    cookie: "Cookie Statement",
    terms: "General Terms of Service",
    privacy: "Privacy Statement",
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={titleText[type]}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="xl"
      PaperProps={{
        sx: {
          borderRadius: {
            xs: "0px",
            sm: "16px",
          },
        },
      }}
    >
      <DialogTitleComp id="modal-modal-title">
        {type && <Translate text={titleText[type]} />}
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitleComp>

      <DialogContent
        sx={{
          userSelect: "none",
          p: 3,
          lineHeight: "1.5",
          fontSize: "1rem",
          fontWeight: "400",
          color: "rgb(99, 115, 129)",
        }}
      >
        <DialogContentComp type={type} />
      </DialogContent>

      <DialogActions sx={{ p: 3 }}>
        <ButtonComp
          text="Close"
          onClick={handleClose}
          sx={{
            fontSize: "15px",
            color: "#F76448",
            "&:hover": {
              backgroundColor: "#f7644814",
            },
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
