import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Input } from "../common/Input";
import { ButtonNext } from "../common/ButtonNext";
import { ErrorMessage } from "../common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { next } from "../../features/activeStepSlice";
import { Translate } from "react-translated";
import { useDebounce } from "../../hooks/useDebounce";

export const Password = () => {
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const regex = /^(?=.*\d).{6,20}$/;
  const message = "passErrMessFE";
  const useDebounceValue = useDebounce(password, 500, regex, message);
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length < 21) {
      setPassword(value);
      dispatch(addErrorMessage(""));
    }
  };

  const handleNext = () => {
    if (useDebounceValue && password) {
      try {
        dispatch(addField({ password: useDebounceValue }));
        dispatch(next());
        dispatch(addErrorMessage(""));
      } catch (error) {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    }
  };

  return (
    <Box className="userBox">
      <Typography
        variant="body3"
        marginBottom={2}
        display="block"
        sx={{ lineHeight: "18px" }}
      >
        <Translate text={`password text_${dynamicType}`} />
      </Typography>

      <Input
        value={password}
        handleChange={handleChange}
        placeholder="password value"
        typeInput="password"
        isValid={!useDebounceValue || !password}
      />

      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

      <ButtonNext
        onClick={handleNext}
        text="Next"
        disabled={!useDebounceValue || !password}
      />
    </Box>
  );
};
