import React from "react";
import { Box, styled } from "@mui/material";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import looking_active from "./../../images/looking_active.png";
import looking_past from "./../../images/looking_past.png";
import gender_active from "./../../images/gender_active.png";
import gender_past from "./../../images/gender_past.png";
import gender_default from "./../../images/gender_default.png";
import seeking_active from "./../../images/seeking_active.png";
import seeking_past from "./../../images/seeking_past.png";
import seeking_default from "./../../images/seeking_default.png";

const StepperCompBox = styled(Box)(({ theme }) => ({
  marginBottom: "30px",
  flex: "0 0 auto",

  [theme.breakpoints.up("xl")]: {
    marginBottom: "40px",
  },
}));

const RangeBox = styled(Box)({
  width: "100%",
  height: "4px",
  background: "#E5E8EB",
  borderRadius: "2px",
});

export const StepperComp = ({ active }) => {
  const widthStep = 3 + (94 / 7) * active;

  return (
    <StepperCompBox>
      <Box
        sx={{
          display: "flex",
          marginBottom: "12px",
          justifyContent: "space-between",
          color: "#E5E8EB",
        }}
      >
        {active === 0 ? (
          <img src={looking_active} alt="looking_active" />
        ) : (
          <img src={looking_past} alt="looking_past" />
        )}

        {active === 1 ? (
          <img src={gender_active} alt="gender_active" />
        ) : active > 1 ? (
          <img src={gender_past} alt="gender_past" />
        ) : (
          <img src={gender_default} alt="gender_default" />
        )}

        {active === 2 ? (
          <img src={seeking_active} alt="seeking_active" />
        ) : active > 2 ? (
          <img src={seeking_past} alt="seeking_past" />
        ) : (
          <img src={seeking_default} alt="seeking_default" />
        )}

        <CakeOutlinedIcon
          sx={{
            color:
              active === 3 ? "#F76448" : active > 3 ? "#FFDC22" : "#E5E8EB",
          }}
        />

        <FmdGoodOutlinedIcon
          sx={{
            color:
              active === 4 ? "#F76448" : active > 4 ? "#FFDC22" : "#E5E8EB",
          }}
        />

        <PersonOutlineOutlinedIcon
          sx={{
            color:
              active === 5 ? "#F76448" : active > 5 ? "#FFDC22" : "#E5E8EB",
          }}
        />

        <LockOutlinedIcon
          sx={{
            color:
              active === 6 ? "#F76448" : active > 6 ? "#FFDC22" : "#E5E8EB",
          }}
        />

        <EmailOutlinedIcon
          sx={{
            color:
              active === 7 ? "#F76448" : active > 7 ? "#FFDC22" : "#E5E8EB",
          }}
        />
      </Box>

      <RangeBox>
        <Box
          width={`${widthStep}%`}
          height="4px"
          sx={{ background: "#FFDC22" }}
        />
      </RangeBox>
    </StepperCompBox>
  );
};
